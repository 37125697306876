.#{$wf__ns}push-notifications {
    &_icon--rotate {
        -webkit-transform: rotate(-25deg);
        -moz-transform: rotate(-25deg);
        -ms-transform: rotate(-25deg);
        -o-transform: rotate(-25deg);
        transform: rotate(-25deg);
        cursor: pointer;
    }
}

.text-center {
  text-align: center;
}
