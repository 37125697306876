.c-datepicker {
    display: inline-block;
    vertical-align: middle;
}

.md-drppicker.double {
  width: 650px !important;
  }

mat-datepicker-toggle {
  display: inline-block;
}