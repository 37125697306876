/**

Template Name: Gradient Able Admin Template
Author: #
Email: #
File: style.scss
**/

/*Config project*/
@import 'config__project';

@import 'settings/custom-material-theme';

@import 'tools/custom-material';

@import 'utilities/color';

@import 'objects/table';

@import 'components/navbar';
@import 'components/container';
@import 'components/div';
@import 'components/table';
@import 'components/list';
@import 'components/spinner';
@import 'components/block';
@import 'components/form';
@import 'components/article';
@import 'components/query-builder';
@import 'components/segmentation-integration';
@import 'components/tooltip';
@import 'components/checkbox';
@import 'components/button';
@import 'components/datepicker';
@import 'components/template';
@import 'components/card';
@import 'components/carousel';
@import 'components/marquee';
@import 'components/snackbar';
@import 'components/push-notifications';
@import 'components/newsletters_sending';
@import 'components/expansion-panel';
@import 'components/titles';
@import 'components/site';
@import 'components/trigger';

@import 'utilities/sizing';
@import 'utilities/position';
@import 'utilities/shadow';
@import 'utilities/padding';

@import 'shame';

@import 'syncfusion';

@import '../../node_modules/swiper/swiper-bundle.min.css';

@import "paywall/products/priority";

html,
body {
    min-height: 100% !important;
    padding-top: 2rem !important;
}

#u_body {
  position: initial;
  top: initial;
  left: initial;
  transform: none;
}

@for $i from 1 through 10 {
  .gap-#{$i} {
    gap: #{$i}rem;
  }
}

.lh-revert {
  line-height: revert;
}
