// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// The warn palette is optional (defaults to red).
$custom-theme-primary: mat.define-palette(mat.$indigo-palette, 500, 300, 700);
$custom-theme-accent: mat.define-palette(mat.$grey-palette, 200, 100, 700);
$custom-theme-warn: mat.define-palette(mat.$red-palette, 500, 50, 900);
/* $custom-theme-danger: mat-palette($mat-orange, 500, 50, 900); */

// Define a custom typography config that overrides the font-family // as well as the
// `headlines ` and `body-1` levels.
$custom-typography: mat.define-legacy-typography-config(
  $font-family: 'Nunito, sans-serif',
  $headline: mat.define-typography-level(32px, 48px, 700),
  $body-1: mat.define-typography-level(16px, 24px, 500),
);
// Create the theme object (a Sass map containing all of the palettes).
$custom-theme: mat.define-light-theme((
  color: (
    primary: $custom-theme-primary,
    accent: $custom-theme-accent,
    warn: $custom-theme-warn,
  ),
  //typography: mat.define-typography-config(),
  typography: $custom-typography,
  density: 0,
));

$primary: map-get($custom-theme, primary);
$accent: map-get($custom-theme, accent);
$warn: map-get($custom-theme, warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($custom-theme);
@include mat.legacy-core();

// Custom styles
.mat-button-toggle-checked {
  color: mat.get-color-from-palette($primary, default-contrast) !important;
  background-color: mat.get-color-from-palette($primary) !important;
}
