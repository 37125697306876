
.c-segments-dual-list {
    & mat-selection-list {
        height: 30rem;
    }
    & .mat-pseudo-checkbox-checked {
      @include background-material-color($primary);
    }
}

.c-segments-dual-list-size {
    height: 39vh;
}
