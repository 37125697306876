.page-title{
    margin-bottom: 3rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $gray ;
    h1 {
        font-weight: bold;
        font-family: 'Nunito', sans-serif;
    }
}
.hr-title {
    height: 8px;
    width: 50px;
    bottom: -20px;
    position: absolute;
    border-radius: 50px;
    background-color: $blue;
}