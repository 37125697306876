.c-checkbox {
    &--big {
        & .mat-checkbox-inner-container {
            height: 25px !important;
            width: 25px !important;
          }
    }
}

.mt_check_device {
  margin: 0 5% 0 10%;

  &.mat-checkbox-checked .mat-checkbox-background {
    background-color: #3f51b5 !important;
  }
}


