@use '@angular/material' as mat;
@mixin background-material-color($color) {
    background-color: mat.get-color-from-palette($color) !important;
    color: mat.get-color-from-palette($color, default-contrast) !important;
}

@mixin material-color($color) {
    color: mat.get-color-from-palette($color) !important;
}

@mixin mat-table-columns($columns) {
    .mat-column- {
        @each $colName, $props in $columns {
            $width: map-get($props, 'width');
            &#{$colName} {
                flex: $width;
                @if map-has-key($props, 'color') {
                    color: map-get($props, 'color');
                }
            }
        }
    }
}
