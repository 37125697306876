.c-snackbar-danger {
    background-color: white;
    @include material-color($warn);
    font-weight: bold;

    & .mat-simple-snackbar-action {
        @include material-color($primary);
        @include background-material-color($primary);
        border-radius: 4px;
    }
}