$main-text-color: #111827;
$secondary-text-color: #4B5563;
$active-color: #10B981;
$warning-color: rgb(247, 220, 41);
$error-color: rgb(247, 96, 41);
$client-color: rgb(99, 99, 199);

.priority-container {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.priority-product-list {
  max-width: 100%;
  border: solid 1px rgba(204, 204, 204, 0.562);
  border-radius: 10px;
  min-height: 65px;
  display: block;
  background: rgb(221, 221, 221);
  overflow: hidden;
}

.priority-product-box {
  cursor: grab;
  border: solid;
  border-radius: 20px;
  border-width: 0px 0px 0px 8px;
  display: flex;
  margin: 0.5rem;
  background-color: #ffffff;
  align-items: center;
  height: 4rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.priority-product-box:active {
  cursor: grabbing;
  background-color: lightgrey;
}

.cdk-drag-preview { //PROBAR
  cursor: grabbing;
  background-color: lightgray;

}

.placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 65px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.priority-position {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid;
  border-width: 0px 1px 0px 0px;
  border-color: rgb(206, 206, 206);
}

.priority-position-text {
  color: $secondary-text-color;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.priority-position-index {
  color: $main-text-color;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 800;
}

.priority-product-img {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
}

.priority-product-text {
  width: calc(100% - 4rem);
}

.priority-product-name {
  color: $main-text-color;
  font-family: Nunito, sans-serif;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 800;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.priority-product-id {
  color: $secondary-text-color;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.priority-product-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
}

.condition-success {
  font-size: 16px;
  color: $active-color;
}

.condition-warn {
  font-size: 16px;
  color: $warning-color;
}

.condition-error {
  font-size: 16px;
  color: $error-color;
}

.condition-inactive {
  font-size: 35px;
  color: $client-color;
}

.condition-client {
  display: flex;
  justify-content: center;
  font-size: 35px;
  color: $client-color;
}

.condition-legend {
  font-size: 16px;
  color: gray;
}

.legend-box {
  border: solid;
  border-radius: 20px;
  border-width: 0px 0px 0px 8px;
  height: 22px;
  margin: 3px;
  align-self: center;
}

//COLOR SECTION
.active-card {
  border-color: $active-color;
}

.inactive-card {
  cursor: no-drop;
  border-color: rgb(206, 0, 0);
  background-color: #dadada;
}

.warning-card {
  border-color: $warning-color;
}

.error-card {
  border-color: $error-color;
}

.client-card {
  border-color: $client-color;
}
