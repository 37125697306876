.c-main-navbar {
    position: fixed;
    top: 0;
    z-index: 2;

    &__row {
        justify-content: space-between;
    }

    &__logo {
        width: 35px;
        height: 35px;
    }

    &__links {
        a {
            color: inherit;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            &[disabled] {
                pointer-events: none;
            }
        }
    }

    &__actions {
        flex-grow: 1;
    }

    &__select-form {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
    }

    &__site-link {
        font-size: 0.95rem;
        color: white;
        text-decoration: none;

      &:hover{
        text-decoration: none;
      }
    }

    &__version-select {
        margin-left: 16px;
        font-size: 0.95rem;
        width: 48px !important;
        flex-shrink: 0;
    }

    .mat-select-trigger{
        text-align: right;
    }

    .active {
        height: 100%;
        font-weight: 900;
        border-radius: 0;
        padding-top: 14px;
        background-color: $dark-blue;
        border-bottom: 3px solid white;
    }
}

.mat-tab-link {
    &:hover {
        text-decoration: none;
    }
}
