$blue: #1B55E3;
$gray: #f0f3f7;
$white: #ffffff;
$dark-blue: #161537;
$dark-gray: #756F97;

.primary-color {
    @include material-color($primary);
}

.accent-color {
    @include material-color($accent);
}

.warning-color {
    @include material-color($warn);
}

.bg-accent-color {
    @include background-material-color($accent);
}

.bg-warning-color {
    @include background-material-color($warn);
}

.mat-icon.mat-primary {
    color: $blue;
}

.mat-toolbar.mat-primary, .bg-primary-color {
    background-color: $dark-blue;
}

.font-white {
    color: $white;
}
