.c-button {
    height: 100%;
    width: 100%;
    font-weight: bold;
    background-color: $blue !important;
    font-family: 'Source Sans Pro', sans-serif;
    &--text-big {
        font-size: 1.2rem;
    }
    &--text-medium {
      font-size: 1.0rem;
    }
    &--mw {
        max-width: 300px;
    }
    &--sw {
        max-width: 250px;
    }
    &--br-10 {
        border-radius: 10px !important;
    }
    &--bg-primary-color {
        background-color: $dark-blue !important;
    }

}

.c-button:disabled{
  color: white !important;
  background-color: rgb(161, 160, 160) !important;
}

.c-profile-button {
    height: 100%;
    width: auto;
    &--img {
        width: 3rem;
        span {
            width: 100%;
        }
    }

}

.mat-raised-button.mat-primary{
    background-color: $blue;
}

.mat-radio-outer-circle {
    border-width: 3px !important;
    border-color: $blue !important;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle {
    font-weight: bold;
    background-color: $blue !important;
}
