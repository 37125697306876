

.e-rule-filter{
  width: 17vw !important;
}


.e-query-builder .e-group-body .e-rule-value.e-show {
  width: 22vw !important;
}


.#{$wf__ns}querybuilder {
    .e-rule-filter {
        width: 18rem !important;
    }
    .e-query-builder {
        border: inherit;
    }

    .table {
        width: 100%;
    }

    &__mat-column-querytable {
        word-wrap: break-word !important;
        white-space: unset !important;
        flex: 0 0 10% !important;
        width: 20% !important;
        overflow-wrap: break-word;
        word-wrap: break-word;

        word-break: break-word;

        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }

    &__no-data-block{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 25rem;
        &_data-block__text {
            font-size: 2.5rem;
            font-weight: 600;
            color: #b3b3b3;
        }
    }
    .mat-checkbox.mat-warn {
        label {
            margin-bottom: inherit;
        }
    }
    &__segmentList {
        &-hoverlight:hover {
            background-color: #eef3f3;
            cursor: pointer;
        }
    }
    &__detailsegment {
        &-radiogroup {
            display: flex;
            flex-direction: column;
        }

        &-radiobutton {
            margin: 0.2rem;
        }
    }
}
