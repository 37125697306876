.c-template {
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.05);
    }

    &__img {
        width: 100%;
        height: 15rem;
        border: 1px solid black;
    }
}


.c-template-focus {
  box-shadow: 1px 2px 20px 4px rgb(0 0 0 / 48%), 0 6px 15px 5px rgb(7 7 7 / 55%);;
}
