@import '../utilities/color';

.#{$wf__ns}segmentation-integration {

  display:flex;
  flex-direction: column;

  .input-card {
    margin-top: 1.5em !important;
    margin-bottom: 0em !important;
    flex-flow: column;
  }

  .drag-drop-container {
    display: flex;
    flex-wrap: nowrap;
  }

  .output-card {
    width: 100%;
    margin-top: 1.5em !important;
    margin-bottom: 0em !important;
    flex-direction: column;
  }

  .list {
    border: solid 1px #ccc;
    min-height: 60px;
    display: block;
    background: white;
    border-radius: 4px;
    overflow: hidden;
  }

  .output-list {
    max-height: 515px;
    overflow-y: auto;
  }

  .input-list {
    width: 500px;
    max-width: 100%;
    max-height: 220px;
    overflow-y: auto;
  }

  .child-box {
    display: flex;
  }

  .full-width {
    width: 100%;
  }

  .output-container{
    margin-top: 2.5em;
    margin-left: 10em;
  }

  .example-box {
    padding: 20px 10px;
    border-bottom: solid 1px #ccc;
    color: rgba(0, 0, 0, 0.87);
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: white;
    font-size: 14px;
  }

  .datasource-box-color {
    background-color: $blue;
    color: $white;
  }

  .segments-box-color {
    background-color: $gray;
  }

  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
                0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .cdk-drag-placeholder {
    opacity: 0;
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .example-box:last-child {
    border: none;
  }

  .example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

}

.segOpCenter {
  justify-content: space-around;
}

.datapickerHeight {
  height: 2.0vh;
}

.datapickerIcon{
  font-size: 1.15em;
}

.operationInfo{
  background-color: #F0F0F0;

}

@media only screen and (max-width: 1400px) {
  .radio-chart{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}



@media only screen and (min-width: 1401px) {
  .radio-chart{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

