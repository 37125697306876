.c-article {
    &__img {
        height: 15rem;
        width: 100%;
    }

    &__title {
        height: 6rem;
        width: 100%;
    }
}