.c-no-data-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: center;
    &__text {
        font-size: 2.5rem;
        font-weight: 600;
        &--gray {
            color: #b3b3b3;
        }
    }
    &__field {
        &--gray {
            color: #b3b3b3;
        }
    }
}