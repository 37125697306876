.c-marquee {
    height: 3em;

    overflow: hidden;
    position: relative;

    &__content {
        width: 400%;
        height: 100%;

        position: absolute;
        overflow: hidden;

        animation: marquee 7s linear infinite;
        animation-play-state: paused;
        animation-fill-mode: forwards;

        &:hover {
            animation-play-state: running;
        }

        &__text {
            float: left;
            width: 50%;
        }
    }
}

@keyframes marquee {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}
