.close-checkbox {
  margin-top: 1.3em;
  margin-left: 2em;
}

.close-btn-textbox {
  margin-left: 5em;
}

.close-btn-textbox .mat-form-field-wrapper {
  width: 98%;
}

.primary-button-checkbox {
  margin-top: 1.3em;
  margin-left: 3em;
}

.close-trigger-btn {
  margin-top: 1em;
  border-radius: 10em;
  border: solid;
  height: 33px;
  width: 33px;
  border-color: #585858;
  border-width: 1px;
  background-color: white;
  z-index: 1000000000;
  margin-left: 6em;
}

.trigger-btn {
  text-align: center;
  padding-top: 1.2em;
  padding-bottom: 1.2em;
}

.icon-info{
  margin-bottom: 12px;
}

.div-position{
  display: flex;
  align-items: center;
}
