.column {
    float: left;
  }
  .column p {
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
  }
  
  .middle {
    width: 25%;
    text-align: center;
  }
  
  .middle-h {
    width: 25%;
    text-align: center;
    padding: 0px 0px 0px 7px;
  }
  
  .right {
    width: 25%;
    text-align: center;
  }
  
  .right-h {
    width: 25%;
    text-align: center;
    padding: 0px 18px 0px 0px;
  }
  
  .left {
    width: 50%;
  }
  
  .left-h {
    width: 50%;
    padding: 0px 0px 0px 15px;
  }